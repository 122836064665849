import axios from 'axios'
import React, { useEffect, useState } from 'react'
import './dashboard.css'
import UploadWidget from './UploadWidget'
import { PHP_API_DISCO } from '../../API/Routes/config'

export default function Discographie() {

    const [data, setData] = useState([])
    const [current, setCurrent] = useState({})
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [image, setImage] = useState(null)

    const [isPopup, setIsPopup] = useState({isOpen: false, msg: ''})


    const getDiscographie = () => {
        axios.get(PHP_API_DISCO)
        .then(
            function(response){
                setData(response.data)
            }
        )
    }

    useEffect(() => {
        getDiscographie()
    }, [])

    const openModal = (item) => {
        setCurrent(item);

        setIsOpenModal(true)
    }

    const modify = async () => {
        axios.put(PHP_API_DISCO, {
            discographie: current
        })
        .then(
            function(response){
                setIsOpenModal(false)
                setCurrent([])
                getDiscographie()
                setIsPopup({isOpen: true, msg: 'Disque modifier avec succès'})
                setTimeout(() => {
                    setIsPopup({isOpen: false, msg:''})
                }, 2500);
            }
        )
    }

    const addDiscographie = () => {

        axios.post(PHP_API_DISCO, {
            discographie: current
        })
        .then(
            function(response){
                console.log(response)
                if(response.status === 200){
                    setIsOpenModal(false)
                    setCurrent([])
                    getDiscographie()
                    setIsPopup({isOpen: true, msg: 'Disque ajouter avec succès'})
                    setTimeout(() => {
                        setIsPopup({isOpen: false, msg:''})
                    }, 2500);
                }
            }
        )
    }

    const deleteDiscographie = async () => {

        axios.delete(PHP_API_DISCO, {params: {'id': current.id}})
        .then(
            function(response){
                console.log(response)
                if(response.status === 200){
                    setIsOpenModal(false)
                    getDiscographie()
                    setIsPopup({isOpen: true, msg: 'Disque supprimer avec succès'})
                    setTimeout(() => {
                        setIsPopup({isOpen: false, msg:''})
                    }, 2500);
                }
            }
        )
    }

    useEffect(() => {
        if (image){
            setCurrent({...current, cover: image})
        }
    }, [image])

    const closeReset = () => {
        setIsOpenModal(false)
        setCurrent([])
    }

    return (
        <div>
            {
                isPopup.isOpen &&
                <div className='popup'>
                    <p>{isPopup.msg}</p>
                </div>
            }
            <p className='addButton' onClick={() => setIsOpenModal(true)}>Ajouter</p>
            <table className='dashboard__table'>
                <thead className='header__table'>
                    <tr>
                        <th>Titre</th>
                        <th>Artiste</th>
                        <th>Cover</th>
                        <th>Place</th>
                    </tr>
                </thead>
                <tbody style={{width: '100%'}}>
                    
                    {
                        data.map(item => {
                            return (
                                <tr style={{height: 50}} onClick={() => openModal(item)}>
                                    <td align='center'><p>{item.titre}</p></td>
                                    <td align='center'><p>{item.artiste}</p></td>
                                    <td align='center'>{
                                        item.cover &&
                                        <img src={item.cover} style={{width: 40, height: 40, borderRadius: '50%'}} />
                                    }</td>
                                    <td align='center'><p>{item.place}</p></td>
                                </tr>
                                
                            )
                        })
                    }
                    
                </tbody>
           
            </table>
            {
                isOpenModal && 

                <div style={{position: 'absolute', width: '99vw', height: '100vh', top: 0, left: 0}}>
                    <div className='modalBackground' onClick={() => setIsOpenModal(false)}></div>
                    <div className='modal'>
                        <div className='modalSub'>
                            <div>
                                <p>Titre : </p>
                                <input type='text' value={current.titre} onChange={(e) => setCurrent({...current, titre: e.target.value})} />
                            </div>
                            <div>
                                <p>Url : </p>
                                <input type='text' value={current.url} onChange={(e) => setCurrent({...current, url: e.target.value})} />
                            </div>
                        </div>
                        <div className='modalSub'>
                            <div>
                                <p>Artiste : </p>
                                <input type='text' value={current.artiste} onChange={(e) => setCurrent({...current, artiste: e.target.value})} />
                            </div>
                            <div>
                                <p>Pays : </p>
                                <input type='text' value={current.pays} onChange={(e) => setCurrent({...current, pays: e.target.value})} />
                            </div>
                        </div>
                        <div className='modalSub'>
                            <div>
                                <p>Place : </p>
                                <input type='number' min={0}  value={current.place} onChange={(e) => setCurrent({...current, place: e.target.value})} />
                            </div>
                            <div>
                                <p>Gold : </p>
                                <input type='number' min={0}  value={current.gold} onChange={(e) => setCurrent({...current, gold: e.target.value})} />
                            </div>
                            <div>
                                <p>Platine : </p>
                                <input type='number' min={0} value={current.platine} onChange={(e) => setCurrent({...current, platine: e.target.value})} />
                            </div>
                        </div>
                        <div className='modalSub'>
                            <div>
                                <p>Image : </p>
                                <UploadWidget setCurrent={setImage} current={image} />
                            </div>
                        </div>
                        <div>
                            {
                                current.cover &&
                                <img src={current.cover} style={{maxHeight: '30vh'}} />
                            }
                        </div>
                        <div className='subActions'>
                                <p className='enregistrer' onClick={() => {!current.id ? addDiscographie() : modify()}}>Enregistrer</p>
                                <p className='annuler' onClick={() => closeReset()}>Annuler</p>
                                <p className='supprimer' onClick={() => deleteDiscographie()}>Supprimer</p>
                            </div>
                        
                    </div>
                </div>
            }
        </div>
    )

}
