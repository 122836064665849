import axios from 'axios'
import React, { useEffect, useState } from 'react'
import UploadWidget from './UploadWidget'
import { PHP_API_BACK, PHP_API_INFOS } from '../../API/Routes/config'

export default function Background() {

    const [data, setData] = useState('')
    const [isPopup, setIsPopup] = useState({isOpen: false, msg: ''})


    const getInfos = () => {
        axios.get(PHP_API_INFOS)
        .then(
            function(response){
                console.log(response)
                setData(response.data)
            }
        )
    }

    useEffect(() => {
        getInfos()
    }, [])


    const modify = async () => {
        axios.put(PHP_API_INFOS, {
            user: data
        })
        .then(
            function(response){
                getInfos()
                setIsPopup({isOpen: true, msg: 'Background modifier avec succès'})
                setTimeout(() => {
                    setIsPopup({isOpen: false, msg:''})
                }, 2500);
            }
        )
    }


    return (
        <div style={{ gap: 16, color: 'white'}}>
            {
                isPopup.isOpen &&
                <div className='popup'>
                    <p>{isPopup.msg}</p>
                </div>
            }
            <div>
                <p>Email : </p>
                <input style={{padding: 5}} type='text' value={data.email} onChange={(e) => setData({...data, email: e.target.value})} />
            </div>
            <div style={{marginTop: 8}}>
                <p>Password : </p>
                <input style={{padding: 5}} type='password' value={data.password} onChange={(e) => setData({...data, password: e.target.value})} />   
            </div>
            <p className='enregistrer' style={{display: 'inline-block', marginTop: 8}} onClick={() => modify()}>Enregistrer</p>
        </div>
    )
}
