import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { PHP_API_PROD, PHP_API_REVEAL } from '../../API/Routes/config';

import './Productions.css'
 
import {gsap, Expo} from 'gsap'; 
import { useTranslation } from 'react-i18next';

import spotify from "../../images/spotify.png"
import soundcloud from "../../images/deezer.png"
import youtube from "../../images/youtube.png"

import chevron from "../../images/chevron.png"

import logo from '../../images/pancho ok.png'
 
function Productions() {

    const ref = useRef(null)

    const navigate = useNavigate();

    const [language, setLanguage] = React.useState(window.localStorage.getItem('language') !== null ? window.localStorage.getItem('language') : 'fr')

    const [productions, setProductions] = React.useState([])
    const [reveal, setReveal] = React.useState(false)

    const [t, i18n] = useTranslation('global');

    useEffect(() => {
        window.localStorage.setItem('language', language);
        i18n.changeLanguage(language);
        setReveal(new Date(reveal))
    }, [language, i18n]);

    const refreshData = async () => {

        try {

            await axios.get(PHP_API_REVEAL)
            .then(data => {
                setReveal(new Date(data.data.date.replace(/-/g, "/")))
            })
            

            await axios.get(PHP_API_PROD)
                .then(data => {
                    setProductions(data.data)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const transition_content = () => {
        const tl = gsap.timeline({
            onComplete: () => {
            }
        })

        tl.to(".transition__content", {
            delay: 0.5,
            duration: 0.5,
            opacity: 0,
            top: "90%"
        })
    } 

    const Reaveal = () => {

        if (document.querySelector(".transition__content") !== null && document.querySelector(".transition__container") !== null && document.querySelector(".head__main__container__right") !== null && document.querySelector(".production__container__left") !== null && document.querySelector(".production__marquee__container") !== null && document.querySelector(".head__container__left") !== null && ref.current !== null) {
            
            ref.current.innerHTML = ref.current.innerText.split('').map((char, index) => 
                `<span style="transform:rotate(${index  * 8.6 * 1.5}deg)">${char}</span>`
            ).join('');

            const tl = gsap.timeline({
                onComplete: () => {
                }
            })

            transition_content()
            
            tl.to("body", { duration: 0, delay: 0, overflow: "hidden" })
            .to("html", { duration: 0, delay: 0, overflow: "hidden"  })
            .to(".transition__container", {
                duration: 0.5,
                delay: 0.5,
                transform: "translateY(100%)",
            }).to(".transition__container", {
                delay: 0,
                duration: 0,
                display: "none",
            }).to(".head__main__container__right", {
                duration: 0.1,
                delay: 0.1,
                opacity: 1,
                ease: Expo.easeInOut
            }).to(".production__container__left", {
                duration: 0.4,
                delay: 0.1,
                opacity: 1,
                ease: Expo.easeInOut
            }).to(".production__marquee__container", {
                duration: 0.4,
                delay: 0.1,
                opacity: 1,
            }).to(".head__container__left", {
                opacity: 1,
                ease: Expo.easeInOut
            }).to("html", { duration: 0, delay: 0, overflow: ""  })
            .to("body", { duration: 0.4, delay: 0, overflow: "" })
        }
    }

   
    
    useEffect(() => {
        Reaveal()
        refreshData()
    }, [])

    if (productions == null) return ( <div>
        <div 
            className='transition__container'
            style={{
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "#8806CE",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 2
            }}
        />
        <p 
            className='transition__content' 
            style={{
                position: "absolute", 
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "black", 
                zIndex: 2, 
                fontFamily: "deathrattle",
                fontSize: 70
            }}
        >
            Panchogyps
        </p>
    </div>)
   
        return (
            <main className='main__productions main'>

                <div>
                    <div 
                        className='transition__container'
                        style={{
                            position: "absolute",
                            top: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#8806CE",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 2
                        }}
                    />
                    <p 
                        className='transition__content' 
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "black", 
                            zIndex: 2, 
                            fontFamily: "deathrattle",
                            fontSize: 70
                        }}
                    >
                        Panchogyps
                    </p>
                </div>

                

                    {/* <h3 className='head__container__right' onClick={() => navigate('/')}>PanchoGyps</h3> */}
                    <img style={{zIndex: 1}} className='head__main__container__right' onClick={() => navigate('/')} src={logo} alt="Pancho miniature"></img>
                    <div style={{zIndex: 1}} className='head__container__left head__main__container__left'>
                        <p onClick={() => setLanguage("es")} style={{color: language === "es" ? "#ffffff" : "#ACACAC"}}>es</p>
                        <p>-</p>
                        <p onClick={() => setLanguage("en")} style={{color: language === "en" ? "#ffffff" : "#ACACAC"}}>en</p>
                        <p>-</p>
                        <p onClick={() => setLanguage("fr")} style={{color: language === "fr" ? "#ffffff" : "#ACACAC"}}>fr</p>
                    </div>

                <div className='production__container'>

                    <div className='production__container__left'>
                        <h1>{t("productions.titre")}</h1>
                        <p className='production__description'>{t("productions.sousTitre")}</p>

                        <div className="production__container__circle">
                            <div className="production__container__circle__text">
                                <p ref={ref}>
                                    reavel - reavel - reavel - 
                                </p>
                            </div>
                            <div className="production__container__circle__text__static">
                                <p>{new Date(reveal).toLocaleDateString(language, {day: "numeric"})}</p>
                                <p>{new Date(reveal).toLocaleDateString(language, {month: "long"})}</p>
                            </div>
                        </div>
                    </div>

                    
                
                    <div className='production__marquee__container'>
                        <div className='production__marquee__top'>
                            {
                                productions.filter((prod, index) => {
                                    return prod.position === 1
                                })
                                .map((production, index) => {
                                    
                                    return (
                                        <ProductionCover key={index} cover={production.cover} title={production.titre} url={production.url} index={index} />
                                    )
                                })
                            }
                            {
                                productions.filter((prod, index) => {
                                    return prod.position === 1
                                })
                                .map((production, index) => {
                                    return (
                                        <ProductionCover key={index} cover={production.cover} title={production.titre} url={production.url} index={index} />

                                    )
                                })
                            }
                        </div>
                        <div className='production__marquee__bottom'>
                        {
                                productions.filter((prod, index) => {
                                    return prod.position === 2
                                })
                                .map((production, index) => {
                                    return (
                                        <ProductionCover key={index} cover={production.cover} title={production.titre} url={production.url} index={index} />
                                    
                                    )
                                })
                            }
                            
                            { 
                                productions.filter((prod, index) => {
                                    return prod.position === 2
                                })
                                .map((production, index) => {
                                    return (
                                        <ProductionCover key={index} cover={production.cover} title={production.titre} url={production.url} index={index} />
                                  
                                    )
                                })
                            }
                        </div>
                    </div>    
                </div>

                <script></script>
            </main>
        )
}

const ProductionCover = ({cover, title, index, url}) => {

    const ref = useRef(null)

    const sub_ref = useRef(null)

    useEffect(() => {
        const interval = setInterval(() => {


            if (window.innerWidth < 600 ) {
                if (window.innerHeight <750) {
                    if(ref.current.getBoundingClientRect().top < 440 && ref.current.getBoundingClientRect().top > 320) {
                        ref.current.style.filter = "blur(4px)"
                        sub_ref.current.style.opacity = 1
                    } else {
                        ref.current.style.filter = "blur(0)"
                        sub_ref.current.style.opacity = 0
                    }
                } else if (window.innerHeight > 750 && window.innerHeight < 1000) {
                    if(ref.current.getBoundingClientRect().top < 550 && ref.current.getBoundingClientRect().top > 450) {
                        ref.current.style.filter = "blur(4px)"
                        sub_ref.current.style.opacity = 1
                    } else {
                        ref.current.style.filter = "blur(0)"
                        sub_ref.current.style.opacity = 0
                    }
                }
            }

            
          }, 100);
      
          return () => clearInterval(interval);
    }, [])

    return (
        <a href={url} target='_blank'>
            <div key={index} className='marquee__container'>
                <img ref={ref} className='cover' src={cover} alt={"Album cover " + title} />
                <div ref={sub_ref} className='sub__marquee__container'>
                    <p>Découvrir</p>
                    <img className='chevron' src={chevron} width={30} height={30} alt='chevron'/>
                    <div>
                        <img src={spotify} alt="spotify" />
                        <img src={soundcloud} alt="soundcloud" />
                        <img src={youtube} alt="youtube" />
                    </div>
                    
                </div>
            </div>    
        </a> 
    )
}

export default Productions