import axios from 'axios'
import React, { useEffect, useState } from 'react'
import UploadWidget from './UploadWidget'
import { PHP_API_BACK } from '../../API/Routes/config'

export default function Background() {

    const [data, setData] = useState('')
    const [isPopup, setIsPopup] = useState({isOpen: false, msg: ''})
    const [ext, setExt] = useState('')


    const getbackground = () => {
        axios.get(PHP_API_BACK)
        .then(
            function(response){
                let splits = response.data.url.split('.')
                setExt(splits.slice(-1))
                setData(response.data)
            }
        )
    }

    useEffect(() => {
        getbackground()
    }, [])

    const modify = async () => {
        axios.put(PHP_API_BACK, {
            background: data
        })
        .then(
            function(response){
                setIsPopup({isOpen: true, msg: 'Background modifier avec succès'})
                setTimeout(() => {
                    setIsPopup({isOpen: false, msg:''})
                }, 2500);
            }
        )
    }

    const deleteBackground = async () => {
        axios.put(PHP_API_BACK, {
            background: null
        })
        .then(
            function(response){
                getbackground()
                setIsPopup({isOpen: true, msg: 'Background supprimer avec succès'})
                setTimeout(() => {
                    setIsPopup({isOpen: false, msg:''})
                }, 2500);
            }
        )
    }

    return (
        <div style={{ gap: 16, color: 'white'}}>
            {
                isPopup.isOpen &&
                <div className='popup'>
                    <p>{isPopup.msg}</p>
                </div>
            }
            <p style={{paddingBottom: 16}}>Background : </p>
            <UploadWidget setCurrent={setData} current={data} />
            {
                
                ext[0] !== 'mp4' ?
                    <img src={data.url} style={{maxHeight: '30vh', marginLeft: 16}} />
                :
                <video className='video__background' autoPlay loop muted style={{ width: "10vw", height: "10vh", objectFit: "cover"}}>
                    <source src={data.url !== null ? `${data.url}` : "#171614"} type="video/mp4"/>
                </video>
            }
            <div style={{display: 'flex', gap: 8}}>
                <p className='enregistrer' onClick={() => modify()}>Enregistrer</p>
                <p className='supprimer' onClick={() => deleteBackground()}>Supprimer</p>
            </div>
            
        </div>
    )
}
