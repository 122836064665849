import axios from 'axios'
import React, { useEffect, useState } from 'react'
import UploadWidget from './UploadWidget'
import { PHP_API_TENDANCES } from '../../API/Routes/config'

export default function Tendances() {

    const [clip1, setClip1] = useState({})
    const [clip2, setClip2] = useState({})

    const [image1, setImage1] = useState('')
    const [image2, setImage2] = useState('')

    const [isPopup, setIsPopup] = useState({isOpen: false, msg: ''})

    const getTendances = () => {
        axios.get(PHP_API_TENDANCES)
        .then(
            function(response){
                setClip1(response.data[0])
                setClip2(response.data[1])
            }
        )
    }

    useEffect(() => {
        getTendances()
    }, [])

    useEffect(() => {
        if (image1){
            setClip1({...clip1, image: image1})
        }
    }, [image1])

    useEffect(() => {
        if (image2){
            setClip2({...clip2, image: image2})
        }
    }, [image2])

    const modifyClip1 = async () => {
        axios.put(PHP_API_TENDANCES ,{
            clip: clip1
        })
        .then(
            function(response){
                setIsPopup({isOpen: true, msg: 'Tendance 1 modifier avec succès'})
                setTimeout(() => {
                    setIsPopup({isOpen: false, msg:''})
                }, 2500);
            }
        )
    }

    const modifyClip2 = async () => {
        axios.put(PHP_API_TENDANCES, {
            clip: clip2
        })
        .then(
            function(response){
                setIsPopup({isOpen: true, msg: 'Tendance 2 modifier avec succès'})
                setTimeout(() => {
                    setIsPopup({isOpen: false, msg:''})
                }, 2500);
            }
        )
    }

    return (
        <div style={{display: 'flex', gap: 16, color: 'white'}}>
            {
                isPopup.isOpen &&
                <div className='popup'>
                    <p>{isPopup.msg}</p>
                </div>
            }
            <div style={{width: '50%', display: 'flex', flexDirection: 'column'}}>
                <p>Url :</p>
                <input style={{width: '50%', padding: 6, marginRight: 6}} type='text' value={clip1.url} onChange={(e) => setClip1({...clip1, url: e.target.value})} />
                <div style={{display: 'inline-block', marginTop: 8}}>
                    <UploadWidget setCurrent={setImage1} current={image1}  />
                    {
                        clip1.image &&
                        <img src={clip1.image} style={{maxHeight: '30vh', marginLeft: 16}} />
                    }
                </div>
                
                <div style={{display: 'inline-block',marginTop: 8}}>
                    <p style={{display: 'inline-block'}} className='enregistrer' onClick={() => modifyClip1()}>Enregistrer</p>
                </div>
            </div>
            <div style={{width: '50%', display: 'flex', flexDirection: 'column'}}>

                <p>Url : </p>
                <input style={{width: '50%', padding: 6, marginRight: 6}} type='text' value={clip2.url} onChange={(e) => setClip2({...clip2, url: e.target.value})} />
                <div style={{display: 'inline-block', marginTop: 8}}>
                    <UploadWidget setCurrent={setImage2} current={image2}  />
                    {
                        clip2.image &&
                        <img src={clip2.image} style={{maxHeight: '30vh', marginLeft: 16}} />
                    }
                </div>
                <div style={{display: 'inline-block', marginTop: 8}}>
                    <p style={{display: 'inline-block'}} className='enregistrer' onClick={() => modifyClip2()}>Enregistrer</p>
                </div>
                
            </div>
        </div>
    )
}
