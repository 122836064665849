import React, { useEffect, useRef } from 'react'

import { DISQUES_API_URL, PHP_API_DISCO } from '../../API/Routes/config';

import './Discographie.css'

import Header from '../../components/Header/Header';

import {gsap, Expo} from 'gsap'; 

import { useTranslation } from 'react-i18next';

import disqueOr from '../../images/cd3.png'
import disquePlatine from "../../images/cd.png"

import axios from 'axios'


function Discographie() {

    const [filter, setFilter] = React.useState("")

    const [data, setData] = React.useState(null)
    const [loading, setLoading] = React.useState(true)

    const [t] = useTranslation('global');

    const ref = useRef();

    const refreshData = async () => {

        try {
            axios.get(PHP_API_DISCO)
            .then(data => {
                setData(data.data)
                setLoading(false)
            })
            
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        refreshData()
    }, [])
    

    function MouseOver(event) {
        event.target.style.background = 'white';
        event.target.style.color = 'black';
    }
    function MouseOut(event){

        let filtre = "";

        if (event.target.innerText === "France" || event.target.innerText === "Francia"){
            filtre = "France"
        } else if (event.target.innerText === "Angleterre" || event.target.innerText === "England" || event.target.innerText === "Inglaterra"){
            filtre = "Angleterre"
        } else if (event.target.innerText === "Spain" || event.target.innerText === "Espagne" || event.target.innerText === "España"){
            filtre = "Espagne"
        }

        filter === filtre ? event.target.style.background="white" : event.target.style.background="none";
        filter === filtre ? event.target.style.color="black" : event.target.style.color="#ACACAC";
    }    


    const transition_content = () => {
        const tl = gsap.timeline({
            onComplete: () => {
            }
        })

        tl.to(".transition__content", {
            delay: 0.5,
            duration: 0.5,
            opacity: 0,
            top: "90%"
        })
    }

    const Reveal = () => {

        if (document.querySelector(".transition__container") != null && document.querySelector(".transition__content") != null && document.querySelector(".header__discographie__title") != null && document.querySelector(".header__discographie__right__container") != null && document.querySelector(".discographie__container") != null) {
            const tl = gsap.timeline({
                onComplete: () => {
                }
            })

            transition_content()

            tl.to("body", { duration: 0, delay: 0, overflow: "hidden" })
            .to("html", { duration: 0, delay: 0, overflow: "hidden"  })
            .to(".transition__container", {
                duration: 0.5,
                delay: 0.5,
                transform: "translateY(100%)",
            }).to(".transition__container", {
                delay: 0,
                duration: 0,
                display: "none",
            })
            .to(".header__discographie__title", {
                duration: 0.5,
                delay: 0.1,
                transform: 'translateY(0%)',
                ease: Expo.easeInOut
            }).to(".header__discographie__right__container", {
                duration: 0.4,
                delay: 0.1,
                transform: 'translateY(0%)',
            }).to(".discographie__container", {
                duration: 0.2,
                delay: 0.1,
                opacity: 1,
            })
            .to("html", { duration: 0, delay: 0, overflow: ""  })
            .to("body", { duration: 0.4, delay: 0, overflow: "" })
        }
    } 
    const onWheel = (e) => {
        const elelemnt = ref.current;
        if (elelemnt) {
          if (e.deltaY == 0) return;
          elelemnt.scrollTo({
            left: elelemnt.scrollLeft + e.deltaY,
          });
        }
    };

    Reveal()

    console.log(filter)

    if (loading || data == null) return ( <div>
        <div 
            className='transition__container'
            style={{
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "#FD6A02",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 2
            }}
        />
        <p 
            className='transition__content' 
            style={{
                position: "absolute", 
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "black", 
                zIndex: 2, 
                fontFamily: "deathrattle",
                fontSize: 70
            }}
        >
            Panchogyps
        </p>
    </div>)

    else {

    return (

        <main className='main'>

        <div>
                <div 
                    className='transition__container'
                    style={{
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#FD6A02",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 2
                    }}
                />
                <p 
                    className='transition__content' 
                    style={{
                        position: "absolute", 
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "black", 
                        zIndex: 2, 
                        fontFamily: "deathrattle",
                        fontSize: 70
                    }}
                >
                    Panchogyps
                </p>
            </div>
            
            <Header />

            <div className='header__discographie__container'>

                <h1 className='header__discographie__title' style={{transform: "translateX(-300%)"}}>{t("discographie.titre")}</h1>

                <div className='header__discographie__right__container' style={{transform: "translate(200%)"}}>

                    <p 
                        onClick={() => setFilter("France")} 
                        onMouseOver={MouseOver} 
                        onMouseOut={MouseOut}
                        style={{
                            background: filter === "France" ? "white" : "none",
                            color: filter === "France" ? "black" : "#ACACAC"
                        }}
                    >
                        {t("discographie.filters.1")}
                    </p>
                    <i>-</i>

                    <p 
                        onClick={() => setFilter("Angleterre")} 
                        onMouseOver={MouseOver} 
                        onMouseOut={MouseOut}
                        style={{
                            background: filter === "Angleterre" ? "white" : "none",
                            color: filter === "Angleterre" ? "black" : "#ACACAC"
                        }}
                    >
                        {t("discographie.filters.2")}
                    </p>
                    <i>-</i>

                    <p 
                        onClick={() => setFilter("Espagne")} 
                        onMouseOver={MouseOver} 
                        onMouseOut={MouseOut}
                        style={{
                            background: filter === "Espagne" ? "white" : "none",
                            color: filter === "Espagne" ? "black" : "#ACACAC"
                        }}
                    >
                        {t("discographie.filters.3")}
                    </p>

                    <p onClick={() => setFilter("")}>x</p>
                </div>
            </div>

            <div className='disco__container'>
                <div className='discographie__container'  ref={ref} onWheel={onWheel}>
                    {
                        filter === "" &&
                            data.map((item, index) => {

                                let gold = []
                                let platine = []

                                let platineLeft = 0

                                if (item.platine !== null){
                                    for (let i = 0; i < item.platine; i++) {

                                        platineLeft = platineLeft + 8
                                        if (i === 0){

                                            platine.push(<img key={i + "platine"} src={disquePlatine} alt='platine' width={30} style={{position: "absolute", top: 0}}/>)
                                        } else if (i === 1) {
                                            platine.push(<img key={i + "platine"} src={disquePlatine} alt='platine' width={30} style={{position: "absolute", top: 0, paddingLeft: platineLeft}}/>)
                                        } else if (i === 2) {
                                            
                                            platine.push(<img key={i + "platine"} src={disquePlatine} alt='platine' width={30} style={{position: "absolute", top: 0, paddingLeft: platineLeft + 8}}/>)
                                        } else if (i === 3) {
                                            platine.push(<img key={i + "platine"} src={disquePlatine} alt='platine' width={30} style={{position: "absolute", top: 0, paddingLeft: platineLeft + 16}}/>)
                                        }
                                    }
                                } 

                                let left = platineLeft

                                if (item.gold !== null){
                                    for (let i = 0; i < item.gold; i++) {

                                        left = left + 16
                                        if (i === 0){
                                            if (item.platine > 0){
                                                gold.push(<img key={i + "gold"} src={disqueOr} alt='gold' width={27} style={{position: "absolute", top: 0, paddingLeft: platineLeft + 16}}/>)
                                            } else {
                                                gold.push(<img key={i + "gold"} src={disqueOr} alt='gold' width={27} style={{position: "absolute", top: 0}}/>)
                                            }
                                        } else {
                                            if (item.platine > 0){
                                                gold.push(<img key={i + "gold"} src={disqueOr} alt='gold' width={27} style={{position: "absolute", top: 0, paddingLeft: left}}/>)
                                            } else { 
                                                gold.push(<img key={i + "gold"} src={disqueOr} alt='gold' width={27} style={{position: "absolute", top: 0, paddingLeft: 16}}/>)
                                            }
                                        }
                                    }
                                } 
                                return (
                                    <a key={index}  href={`${item.url}`} target='_blank'>
                                        <div key={index} className='discographie__item__container'>
                                            <h5>{item.artiste}</h5>
                                            <p>{item.titre}</p>
                                            <p className='discographie__item__container__sub' style={{height: 30, position: "relative"}}>
                                                {
                                                    platine.length > 0 &&
                                                        <i style={{position: "relative", height: 30}}>
                                                            {platine}
                                                        </i>
                                                }
                                                
                                                {
                                                    gold.length > 0 &&
                                                        <i style={{position: "relative", height: 30}}>
                                                            {gold}
                                                        </i>
                                                }
                                            </p>
                                            <img className='cover__disco' src={item.cover} alt='cover'/>
                                        </div>
                                    </a>

                                )
                            })
                    }  
                    {
                        filter !== "" &&
                            data.filter(item => item.pays === filter).map((item, index) => {

                                let gold = []
                                let platine = []

                                if (item.platine !== null){
                                    for (let i = 0; i < item.platine; i++) {
                                        platine.push(<img key={i + "platine"} src={disquePlatine} alt='platine' width={30} style={{position: "absolute", top: 0}}/>)
                                    }
                                } 

                                let left = 8

                                if (item.gold !== null){
                                    for (let i = 0; i < item.gold; i++) {

                                        left = left + 16
                                        if (i === 0){
                                            if (item.platine > 0){
                                                gold.push(<img key={i + "gold"} src={disqueOr} alt='gold' width={27} style={{position: "absolute", top: 0, paddingLeft: 24}}/>)
                                            } else {
                                                gold.push(<img key={i + "gold"} src={disqueOr} alt='gold' width={27} style={{position: "absolute", top: 0}}/>)
                                            }
                                        } else {
                                            if (item.platine > 0){
                                                gold.push(<img key={i + "gold"} src={disqueOr} alt='gold' width={27} style={{position: "absolute", top: 0, paddingLeft: left}}/>)
                                            } else {
                                                gold.push(<img key={i + "gold"} src={disqueOr} alt='gold' width={27} style={{position: "absolute", top: 0, paddingLeft: 16}}/>)
                                            }
                                        }
                                    }
                                } 
                                
                                return (
                                    <a key={index}  href={`${item.url}`} target='_blank'>
                                        <div key={index} className='discographie__item__container'>
                                            <h5>{item.artiste}</h5>
                                            <p>{item.titre}</p>
                                            <p className='discographie__item__container__sub' style={{height: 30, position: "relative"}}>
                                                {
                                                    platine.length > 0 &&
                                                        <i style={{position: "relative", height: 30}}>
                                                            {platine}
                                                        </i>
                                                }
                                                
                                                {
                                                    gold.length > 0 &&
                                                        <i style={{position: "relative", height: 30}}>
                                                            {gold}
                                                        </i>
                                                }
                                            </p>
                                            <img className='cover__disco' src={item.cover} alt='cover'/>
                                        </div>
                                    </a>
                                )
                            })
                    }             
                </div>
            </div>
        </main>
    )
    }
}

export default Discographie