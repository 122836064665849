import React, { useEffect } from 'react'
import Header from '../../components/Header/Header'

import './About.css'

import image1 from '../../images/about/image1.jpeg'

import studio1 from '../../images/about/Studio-02.jpg'
import studio2 from '../../images/about/Studio-64.jpg'
import studio3 from '../../images/about/Studio-70.jpg'

import groupe3 from '../../images/about/pirris.png'


import {gsap} from 'gsap'; 

import { useTranslation } from 'react-i18next';


function About() {

    const [t] = useTranslation('global');

    const transition_content = () => {
        const tl = gsap.timeline({
            onComplete: () => {
            }
        })

        tl.to(".transition__content", {
            delay: 0.5,
            duration: 0.5,
            opacity: 0,
            top: "90%"
        })
    }

    const Reveal = () => {
        const tl = gsap.timeline({
            onComplete: () => {
            }
        })

        transition_content()

        tl
        .to(".transition__container", {
            duration: 0.5,
            delay: 0.5,
            transform: "translateY(100%)",
        }).to(".transition__container", {
            delay: 0,
            duration: 0,
            display: "none",
        }).to(".about__container__image", {
            delay: 0.1,
            duration: 0.6,
            opacity: 1,
        }).to(".about__container__text h1", {
            duration: 0.3,
            delay: 0.1,
            transform: "translateX(0)"
        }).to(".about__container__text div", {
            duration: 0.3,
            delay: 0.1,
            transform: "translateX(0)"
        })
    }

    useEffect(() => {
        Reveal()
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <main>

            <div>
                <div 
                    className='transition__container'
                    style={{
                        position: "absolute", 
                        top: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#FC0FC0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 2
                    }}
                />
                <p 
                    className='transition__content' 
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "black", 
                        zIndex: 2, 
                        fontFamily: "deathrattle",
                        fontSize: 70
                    }}
                >
                    Panchogyps
                </p>
            </div>
            <Header />

            <section className="about">

                <div className="about__container">

                    <div className="about__container__image">
                        <img src={image1} alt="profil cover about" />
                    </div>

                    <div className="about__container__text">

                        <h1>{t("about.titre1")}</h1>

                        <div> 
                            <p>
                                {t("about.desc1.head")}<br/>
                            </p>
                            <p>{t("about.desc1.i")}<br /></p> 

                           
                            
                            <p>
                                {t("about.desc1.p")}
                            </p>

                            <p>
                                {t("about.desc1.p2")}
                            </p>
                            <p>
                                {t("about.desc1.p3.1")}
                            </p>
                        </div>
                    </div>

                </div>

                <div className='about__container__studio'>
                    <div className='about__studio__container'>
                        <h2>{t("about.titre2")}</h2>
                        <div className='about__studio__pictures__head'>
                            <img src={studio2} alt='Studio presentation 1'/>
                            <div className='about__studio__pictures__body'>
                                <img src={studio1} alt='presentation des équipements du studio'/>
                                <img src={studio3} alt="presentation du studio 2"/>
                            </div>
                        </div>
                    </div>

                    <div className='about__studio__foot'>
                            <a href='mailto:pirriseditions@gmail.com'>
                                {t("about.desc2.1")}
                            </a>
                    </div>
                    
                    {/* <div className='about__studio__bottom__actions__container'>
                        <div className='about__studio__bottom__item'>
                            <div>
                                <img src={groupe1} alt="action register music"/>
                            </div>
                            <div>
                                <p>
                                    {t("about.desc2.2.1")}
                                </p>
                                <p>
                                    {t("about.desc2.2.2")}                                
                                </p>
                            </div>
                        </div>

                        <div className='about__studio__bottom__item'>
                            <div>
                                <img src={groupe2} alt="action listen music"/>
                            </div>
                            <div>
                                <p>
                                    {t("about.desc2.3.1")}
                                </p>
                                <p>
                                    {t("about.desc2.3.2")}                                
                                </p>
                            </div>
                        </div>
                    </div> */}

                </div>

                <div className='about__label__container'>

                            <div className='about__label__container__left'>
                                <>
                                    <p></p>
                                    <h2>{t("about.titre3")}</h2>  
                                </>
                                <>

                                    <p>Pirris Productions</p> 
                                    <p dangerouslySetInnerHTML={{__html: t("about.desc3.1")}}>
                                        
                                    </p>
                                </>
                            </div>
 
                            <div className='about__label__container__right'>
                                <img src={groupe3} alt='label presentation cover' />
                            </div>

                </div>

            </section>

        </main>
    )
}

export default About