import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import {gsap, Expo} from 'gsap'; 
import { useTranslation } from 'react-i18next';

import logo from '../../images/pancho ok.png'   

function Header() {

    const navigate = useNavigate();

    const [language, setLanguage] = React.useState(window.localStorage.getItem('language') !== null ? window.localStorage.getItem('language') : 'fr')
    const [t, i18n] = useTranslation('global');
    
    useEffect(() => {
        window.localStorage.setItem('language', language);
        i18n.changeLanguage(language);
    }, [language , i18n]);

    const Reveal = () => {
        const tl = gsap.timeline({
            onComplete: () => {
    
            }
        })
        tl.to(".head__main__container__right", {
            duration: 0.4,
            delay: 0.1,
            opacity: 1,
            ease: Expo.easeInOut
        }).to(".head__main__container__left", {
            duration: 0.4,
            delay: 0.1,
            opacity: 1,
            ease: Expo.easeInOut
        })
    }

    useEffect(() => {
        Reveal()
    }, [])

    return (
        <header className='head__container'> 
            <img className='head__main__container__right' onClick={() => navigate('/')} src={logo} alt="Pancho miniature"></img>
            {/* <h3 className='head__main__container__right' onClick={() => navigate('/')}>PanchoGyps</h3> */}
            <div className='head__main__container__left'>
                <p onClick={() => setLanguage("es")} style={{color: language === "es" ? "#ffffff" : "#ACACAC"}}>es</p>
                <p>-</p>
                <p onClick={() => setLanguage("en")} style={{color: language === "en" ? "#ffffff" : "#ACACAC"}}>en</p>
                <p>-</p>
                <p onClick={() => setLanguage("fr")} style={{color: language === "fr" ? "#ffffff" : "#ACACAC"}}>fr</p>
                
            </div>
        </header>
    )
}

export default Header