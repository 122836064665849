import React, { useEffect, useRef } from 'react'

const UploadWidget = ({setCurrent, current}) => {
    const cloudinaryref = useRef();
    const widgetRef = useRef()
    useEffect(() => {
        cloudinaryref.current = window.cloudinary;
        widgetRef.current = cloudinaryref.current.createUploadWidget({
            cloudName: 'dhgfqdinb',
            uploadPreset: 'vnk2j8r6'
        }, function(error, result){
            if(result.info.secure_url !== undefined){
                setCurrent(result.info.secure_url)
            }   
        })
    }, [])

    return (
        <button className='uplaodPhotoButton' onClick={() => widgetRef.current.open()}>
            upload
        </button>
    )
}

export default UploadWidget
