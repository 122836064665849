import React, { useEffect, useState } from 'react'

import { CLIPS_API_URL, PHP_API_CLIPS, PHP_API_TENDANCES, TENDANCES_API_URL } from '../../API/Routes/config';

import './Clips.css'

import Header from '../../components/Header/Header';

import {gsap} from 'gsap';  

import { useTranslation } from 'react-i18next'; 

import groupe2 from '../../images/about/Group 2.png'

import axios from 'axios'


function Clips() {

    const [data, setData] = useState(null)
    const [tendances, setTendances] = useState(null)

    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    
    const [t] = useTranslation('global');

    const refreshData = async () => {

        setLoading(true)
        setLoading2(true)

        try {

            await axios.get(PHP_API_TENDANCES)
            .then(data => {
                setTendances(data.data)
                setLoading2(false)
            })
            

            await axios.get(PHP_API_CLIPS)
            .then(data => {
                setData(data.data)
                setLoading(false)
            })
            
        } catch (error) {
            console.log(error)
        }
    }

    const transition_content = () => {
        const tl = gsap.timeline({
            onComplete: () => {
            }
        })

        tl.to(".transition__content", {
            delay: 0.5,
            duration: 0.4,
            opacity: 0,
            top: "90%"
        }).to(".transition__content", {
            delay: 0,
            duration: 0,
            display: "none",
        })
    }


    const Reveal = () => {

        if (document.querySelector(".transition__content") !== null && document.querySelector(".transition__container") !== null && document.querySelector(".title__clips") !== null && document.querySelector(".clips__container__head__left") !== null && document.querySelector(".clips__container__head__right") !== null && document.querySelector(".clips__container__body") !== null && document.querySelector(".clips__container__body__mobile") !== null) {

            const tl = gsap.timeline({
                onComplete: () => {
                }
            })

            transition_content()

            tl
            .to(".transition__container", {
                duration: 0.5,
                delay: 0.5,
                transform: "translateY(100%)",
            }).to(".transition__container", {
                delay: 0,
                duration: 0,
                display: "none",
            })
            .to(".title__clips", {
                duration: 0.4,
                delay: 0.1,
                transform: 'translateY(0%)',
            }).to(".clips__container__head__left", {
                duration: 0.3,
                delay: 0.1,
                transform: 'translateX(0%)',
            }).to(".clips__container__head__right", {
                duration: 0.3,
                delay: 0.1,
                transform: 'translateX(0%)',
            }).to(".clips__container__body", {
                duration: 0.3,
                delay: 0.1,
                width: '100%',
                opacity: 1,
            }).to(".clips__container__body__mobile", {
                duration: 0.3,
                delay: 0.1,
                transform: 'translateY(0%)',
            })
        }
    }


    Reveal()   

    useEffect(() => {
        refreshData()
    }, [])

    if (loading || data === null || tendances === null || loading2) return (<div>
        <div 
            className='transition__container'
            style={{
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "#68B8E6",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 2
            }}
        />
        <p 
            className='transition__content' 
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "black", 
                zIndex: 2, 
                fontFamily: "deathrattle",
                fontSize: 70
            }}
        >
            Panchogyps
        </p>
    </div>)
  
    return (
        <main className='clips_main'>
        
            <div>
                <div 
                    className='transition__container'
                    style={{
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#68B8E6",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 2
                    }}
                />
                <p  
                    className='transition__content' 
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "black", 
                        zIndex: 2, 
                        fontFamily: "deathrattle",
                        fontSize: 70
                    }}
                >
                    Panchogyps
                </p>
            </div>
 
                <Header />
            
                <div className='clips__container'>

                    <h1 className='title__clips' style={{transform: "translateY(-360%)"}}>{t("clips.titre")}</h1>

                    <div className='clips__container__head__pictures'>
                        <a href={tendances[0].url}>
                            <div className='clips__container__head__left' style={{transform: "translateX(-200%)"}} >
                                <p>{t("clips.last")}</p>
                                <img className='header__img' src={tendances[0].image} alt="Last production clip cover" />
                                <img src={groupe2} width={30} alt='play button'/>
                            </div>
                        </a>
                        <a href={tendances[1].url}>
                            <div className='clips__container__head__right' style={{transform: "translateX(200%)"}}>
                                <p>{t("clips.next")}</p>
                                <img className='header__img' src={tendances[1].image} alt="Futur production clip cover"/>
                                <img src={groupe2} width={30} alt='play button'/>
                            </div>
                        </a>
                    </div>

                    <div className='clips__container__body'>
                        <div>
                            <p></p>
                            <p></p>
                            <p>{t("clips.table.titre")}</p>
                            <p>{t("clips.table.artiste")}</p>
                            <p></p>
                        </div>
                        
                        {data.map((item, index) => (
                            <a key={index}  href={`${item.url}`} target='_blank'>
                                <div className='clips__container__body__list__item'>
                                    <p>0{index + 1}</p>
                                        <img src={item.image} alt={"Clip image " + item.titre} />
                                    <p>{item.titre}</p>
                                    <p>{item.artiste}</p>  
                                    <p><img src={groupe2} width={30} alt={"Clip image " + item.titre}  /> </p> 
                                </div>
                            </a>
                        ))}
                        
                    </div>

                    <div className='clips__container__body__mobile'>
                        <p>Derniers clips</p>
                        {data.map((item, index) => (
                            <a key={index} href={item.url} target='_blank'>
                                <div  className='clips__container__body__mobile__list__item'>
                                    <div>
                                        <img src={item.image} alt={"Clip image " + item.titre}  />
                                        
                                    </div>
                                    <div>
                                        <p>{item.titre}</p>
                                        <p>{item.artiste}</p>
                                    </div> 
                                    <p><img src={groupe2} width={30} alt={"Clip image " + item.titre}  /> </p> 
                                </div>
                            </a>
                        ))}
                    </div>

                </div>
        </main>
    )
    
}

export default Clips