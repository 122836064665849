const DEFAULT_API_URL = "https://strapi-pancho.onrender.com/api/";
// const DEFAULT_API_URL = "http://localhost:1337/api/";

export const DISQUES_API_URL = DEFAULT_API_URL + "disques?populate=*&sort=place:desc";
export const CLIPS_API_URL = DEFAULT_API_URL + "clips?populate=*&sort=place:asc";
export const TENDANCES_API_URL = DEFAULT_API_URL + "tendances-clip?populate=*";
export const PRODUCTIONS_API_URL = DEFAULT_API_URL + "productions?populate[disque][populate]=*";
export const REVEAL_API_URL = DEFAULT_API_URL + "reveal";
export const REVEAL_API_BACKGROUND_URL = DEFAULT_API_URL + "background?populate=*";

export const PHP_API_DISCO = 'https://panchogyps.com/api/discographie.php'
export const PHP_API_PROD = 'https://panchogyps.com/api/productions.php'
export const PHP_API_REVEAL = 'https://panchogyps.com/api/reveal.php'
export const PHP_API_BACK = 'https://panchogyps.com/api/background.php'
export const PHP_API_CLIPS = 'https://panchogyps.com/api/clips.php'
export const PHP_API_TENDANCES = 'https://panchogyps.com/api/tendances.php'
export const PHP_API_SERVER = 'https://panchogyps.com/api/server.php'
export const PHP_API_INFOS = 'https://panchogyps.com/api/infos.php'

