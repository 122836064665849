import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { PHP_API_REVEAL } from '../../API/Routes/config'

export default function Reveal() {

    const [data, setData] = useState([])
    const [isPopup, setIsPopup] = useState({isOpen: false, msg: ''})

    const getreveal = () => {
        axios.get(PHP_API_REVEAL)
        .then(
            function(response){
                setData(response.data)
            }
        )
    }

    const changeReveal = async (date) => {

        axios.post(PHP_API_REVEAL, {
            'reveal': date
        }).then(
            function(response){
                setIsPopup({isOpen: true, msg: 'Reveal modifier avec succès'})
                setTimeout(() => {
                    setIsPopup({isOpen: false, msg:''})
                }, 2500);
                getreveal()
            }
        )
    }

    useEffect(() => {
        getreveal()
    }, [])


    return (
        <div style={{display: 'flex', gap: 16, color: 'white', alignItems: 'center'}}>
            {
                isPopup.isOpen &&
                <div className='popup'>
                    <p>{isPopup.msg}</p>
                </div>
            }
            <p>Date de reveal : </p>
            <input style={{ padding: 4,}} onChange={(e) => changeReveal(e.target.value)} type='date' value={data.date}/>
        </div>
    )
}
