import { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, HashRouter } from "react-router-dom";
import Home from './pages/home/Home';
import Productions from './pages/productions/Productions';
import Clips from './pages/clips/Clips';
import Discographie from './pages/discographie/Discographie';
import About from './pages/about/About';
import Admin from './pages/admin/Admin';
import Dashboard from './pages/dashboard/Dashboard';

import {gsap, CSSPlugin, Expo} from 'gsap'; 

import logo from './images/pancho ok.png'

gsap.registerPlugin(CSSPlugin);

function App() {

    const [counter, setCounter] = useState(0)

    useEffect(() => {
        const count = setInterval(() => {
            setCounter(
                (counter) => (
                    counter < 100 ? counter + 1 : clearInterval(count), setCounter(100), Reaveal()
                )
            )  
        }, 25)
    }, [])

    const Reaveal = () => {
        if (document.querySelector(".Follow") !== null && document.querySelector(".Hide") !== null && document.querySelector(".logoHome") !== null && document.querySelector(".Loading") !== null) {
            const tl = gsap.timeline({
                onComplete: () => {

                }

            })
            tl.to("body", { duration: 0, delay: 0, overflow: "hidden" })
            .to(".Follow", {
                duration: 1,
                delay: 0.5,
                width: "100%",
                ease: Expo.easeInOut
            }).to(".Hide", {
                opacity: 0,
                duration: 0.2,
            }).to(".Hide", {
                display: "none",
                duration: 0.2,
                width: 0,
            }).to(".Hide",{
                delay: 0,
                duration: 0,
                display: "none",
            }).to(".Follow", {
                delay: 0.1,
                height: "100%",
                duration: 0.3,
                ease: Expo.easeInOut
            }).to(".logoHome", {
                opacity: 1,
                delay: 0.2,
                duration: 0.5,
                ease: Expo.easeInOut
            }).to(".logoHome",{
                opacity: 0,
                duration: 0.2,
                delay: 0.7,
            }).to(".logoHome",{
                display: "none",
            }).to(".Follow", {
                width: "0%",
                duration: 0.4,
                delay: 0.1,
                ease: Expo.easeInOut
            }).to(".Loading", {
                opacity: 0,
                display:"none"
            }).to("body", { duration: 0, delay: 0, overflow: "" });
        }
    }


    return (
        <>
        {
            

            <div 
                className='Loading'
                    style={{
                        maxHeight: "100vh",
                        height: "100%",
                        width: "100%",
                        background: "#171614",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: 0,
                        zIndex: 10,
                        overflowY: "hidden",
                    }}
            >
                <div 
                    className='Follow'
                    style={{
                        position: "absolute",
                        background: "#8806CE",
                        height: 2,
                        width: 0,
                        left: 0,
                        zIndex: 3,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h3 
                        className='Title'
                        style={{
                            display: "none",
                            fontFamily: "bebasneue, sans-serif",
                            opacity: 0,
                            color: "#8806CE"
                        }}
                    >PanchoGyps</h3>
                    <img className='logoHome' width={250} src={logo} alt="Pancho miniature"></img>
                </div>
                <div
                    className='Hide'
                    style={{
                        position: "absolute",
                        left: 0,
                        height: 2,
                        background: "#fff",
                        transition: "0.4s ease-in-out",
                        zIndex: 2,
                        width: counter + "%"
                    }}
                >

                </div>
            </div>
        }

        <HashRouter>
            <Routes>
                
                   
                
                <Route path="/" element={<Home />} />
                <Route path="/Productions" element={<Productions />} />
                <Route path="/Clips" element={<Clips />} />
                <Route path='/Discographie' element={<Discographie />} />
                <Route path='/Apropos' element={<About />} />
                <Route exact path='/admin' element={<Admin />} />
                <Route path='/Dashboard' element={<Dashboard />} />
                <Route path="*" element={<h1>Not Found</h1>} />
            </Routes>
        </HashRouter>

        </>
    );
}

export default App;
