import axios from 'axios'
import React, { useEffect, useState } from 'react'
import './dashboard.css'
import { PHP_API_DISCO, PHP_API_PROD } from '../../API/Routes/config'

export default function Productions() {

    const [data, setData] = useState([])
    const [current, setCurrent] = useState({})
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [discographie, setDiscographie] = useState([])

    const [isPopup, setIsPopup] = useState({isOpen: false, msg: ''})

    const getProductions = () => {
        axios.get(PHP_API_PROD)
        .then(
            function(response){
                console.log(response)
                setData(response.data)
            }
        )
    }

    const getDiscographie = () => {
        axios.get(PHP_API_DISCO)
        .then(
            function(response){
                setDiscographie(response.data)
            }
        )
    }

    useEffect(() => {
        getProductions()
        getDiscographie()
    }, [])

    const openModal = (item) => {
        setCurrent(item);

        setIsOpenModal(true)
    }

    const closeReset = () => {
        setIsOpenModal(false)
        setCurrent([])
    }

    const addProduction = async () => {
        axios.post(PHP_API_PROD, {
            id: current.discographieId,
            position: current.position
        })
        .then(
            function(response){
                getProductions()
                setIsOpenModal(false)
                setIsPopup({isOpen: true, msg: 'Production ajouter avec succès'})
                setTimeout(() => {
                    setIsPopup({isOpen: false, msg:''})
                }, 2500);
            }
        )
    }

    const modify = async () => {
        console.log(current)
        axios.put(PHP_API_PROD, {
            production: current
        })
        .then(
            function(response){
                getProductions()
                setIsPopup({isOpen: true, msg: 'Production modifier avec succès'})
                setTimeout(() => {
                    setIsPopup({isOpen: false, msg:''})
                }, 2500);
            }
        )
    }

    const deleteProduction = async () => {
        axios.delete(PHP_API_PROD, {params: {'id': current.id}})
        .then(
            function(response){
                getProductions()
                setIsPopup({isOpen: true, msg: 'Production supprimer avec succès'})
                setTimeout(() => {
                    setIsPopup({isOpen: false, msg:''})
                }, 2500);
            }
        )
    }

    return (
        <div>
            {
                isPopup.isOpen &&
                <div className='popup'>
                    <p>{isPopup.msg}</p>
                </div>
            }
            <p className='addButton' onClick={() => setIsOpenModal(true)}>Ajouter</p>
            <table className='dashboard__table'>
                <thead className='header__table'>
                    <tr>
                        <th>Disque</th>
                        <th>cover</th>
                        <th>Position</th>
                    </tr>
                </thead>
                <tbody style={{width: '100%'}}>
                    
                    {
                        data.map(item => {
                            return (
                                <tr style={{height: 50}} onClick={() => openModal(item)}>
                                    <td align='center'><p>{item.titre}</p></td>
                                    <td align='center'>{
                                        item.cover &&
                                        <img src={item.cover} style={{width: 40, height: 40, borderRadius: '50%'}} />
                                    }</td>
                                    <td align='center'><p>{item.position}</p></td>
                                </tr>
                                
                            )
                        })
                    }
                    
                </tbody>
                {
                    isOpenModal && 

                    <div style={{position: 'absolute', width: '99vw', height: '100vh', top: 0, left: 0}}>
                        <div className='modalBackground' onClick={() => setIsOpenModal(false)}></div>
                        <div className='modal'>
                            <div className='modalSub'>
                                <div>
                                    <p>Disque : </p>
                                    <select style={{padding: 5}} onChange={(e) => setCurrent({...current, discographieId: e.target.value})}>
                                        {
                                            discographie.map((item, index) => {
                                                if (item.titre === current.titre){
                                                    return (
                                                        <option value={item.id} selected >{item.titre}</option>
                                                    )
                                                } else {
                                                    return (
                                                        <option value={item.id}>{item.titre}</option>
                                                    )
                                                }
                                                
                                            })
                                        }
                                    </select>
                                </div>
                                <div>
                                    <p>Position : </p>
                                    <input type='number' min={0}  value={current.position} onChange={(e) => setCurrent({...current, position: e.target.value})} />  
                                </div>
                                
                            </div>
                            <div className='subActions'>
                                    <p className='enregistrer' onClick={() => {!current.id ? addProduction() : modify()}}>Enregistrer</p>
                                    <p className='annuler' onClick={() => closeReset()}>Annuler</p>
                                    <p className='supprimer' onClick={() => deleteProduction()}>Supprimer</p>
                                </div>
                            
                        </div>
                    </div>
                }   
            </table>
        </div>
    )

}
