import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import './dashboard.css'
import Clips from './Clips';
import Productions from './Productions';
import Discographie from './Discographie';
import Reveal from './Reveal';
import Background from './Background';
import Tendances from './Tendances';
import Infos from './Infos';

export default function Dashboard() {

    const user = sessionStorage.getItem("user");

    const navigate = useNavigate()

    const [current, setCurrent] = useState(1);

    const [data, setData] = useState([])

    useEffect(() => {
        if (!user){
            navigate('/')
        }
    }, [])


    return (
        <div className='bodyDashboard'>
            <div className='dashboardsub'>
                <div className='dashboard__header'>
                    <p onClick={() => setCurrent(1)} style={{color: current == 1 ? "white" : "rgb(149, 149, 149)"}}>Productions</p>
                    <p onClick={() => setCurrent(2)} style={{color: current == 2 ? "white" : "rgb(149, 149, 149)"}}>Clips</p>
                    <p onClick={() => setCurrent(3)} style={{color: current == 3 ? "white" : "rgb(149, 149, 149)"}}>Discographie</p>
                    <p onClick={() => setCurrent(4)} style={{color: current == 4 ? "white" : "rgb(149, 149, 149)"}}>Reveal</p>
                    <p onClick={() => setCurrent(5)} style={{color: current == 5 ? "white" : "rgb(149, 149, 149)"}}>Background</p>
                    <p onClick={() => setCurrent(6)} style={{color: current == 6 ? "white" : "rgb(149, 149, 149)"}}>Tendances clips</p>
                    <p onClick={() => setCurrent(7)} style={{color: current == 7 ? "white" : "rgb(149, 149, 149)"}}>Infos</p>
                    <p className='unlog' onClick={() => setCurrent(8)}>Se déconnecter</p>
                </div>
                <div className='dashboard__body'>
                    
                    {
                        current === 1 ?

                        <Productions />

                        : current === 2 ?

                        <Clips />

                        : current === 3 ?

                        <Discographie />

                        : current === 4 ? 

                        <Reveal />

                        : current === 5 ?

                        <Background />

                        : current === 6 ?

                        <Tendances />

                        : current === 7 &&

                        <Infos />
                    }
                </div> 
            </div>
            
        </div>
    )
}
