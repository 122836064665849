import axios from 'axios'
import React, { useEffect, useState } from 'react'
import './dashboard.css'
import { Image } from 'cloudinary-react';
import UploadWidget from './UploadWidget';
import { PHP_API_CLIPS } from '../../API/Routes/config';

export default function Clips() {

    const [data, setData] = useState([])

    const [current, setCurrent] = useState([])
    const [image, setImage] = useState(null)
    const [isOpenModal, setIsOpenModal] = useState(false)

    const [isPopup, setIsPopup] = useState({isOpen: false, msg: ''})


    const getClips = () => {
        axios.get(PHP_API_CLIPS)
        .then(
            function(response){
                setData(response.data)
            }
        )
    }

    useEffect(() => {
        getClips()
    }, [])

    const openModal = (item) => {
        setCurrent(item);

        setIsOpenModal(true)
    }

    useEffect(() => {
        if (image){
            setCurrent({...current, image: image})
        }
    }, [image])

    const modify = async () => {

        axios.put(PHP_API_CLIPS, {
            clip: current
        })
        .then(
            function(response){
                setIsOpenModal(false)
                setCurrent([])
                getClips()
                setIsPopup({isOpen: true, msg: 'Clip modifier avec succès'})
                setTimeout(() => {
                    setIsPopup({isOpen: false, msg:''})
                }, 2500);
            }
        )
    }

    const addClip = () => {

        axios.post(PHP_API_CLIPS, {
            clip: current
        })
        .then(
            function(response){
                console.log(response)
                if(response.status === 200){
                    setIsOpenModal(false)
                    setCurrent([])
                    getClips()
                    setIsPopup({isOpen: true, msg: 'Clip ajouter avec succès'})
                    setTimeout(() => {
                        setIsPopup({isOpen: false, msg:''})
                    }, 2500);
                }
            }
        )
    }

    const deleteClip = async () => {

        axios.delete(PHP_API_CLIPS, {params: {'id': current.id}})
        .then(
            function(response){
                console.log(response)
                if(response.status === 200){
                    setIsOpenModal(false)
                    getClips()
                    setIsPopup({isOpen: true, msg: 'Clip supprimer avec succès'})
                    setTimeout(() => {
                        setIsPopup({isOpen: false, msg:''})
                    }, 2500);
                }
            }
        )

    }

    const closeReset = () => {
        setIsOpenModal(false)
        setCurrent([])
    }

    return (
        <div>
            {
                isPopup.isOpen &&
                <div className='popup'>
                    <p>{isPopup.msg}</p>
                </div>
            }
            <p className='addButton' onClick={() => setIsOpenModal(true)}>Ajouter</p>
            <table className='dashboard__table'>
                <thead className='header__table'>
                    <tr>
                        <th>Titre</th>
                        <th>Artiste</th>
                        <th>Place</th>
                    </tr>
                </thead>
                <tbody style={{width: '100%'}}>
                    
                    {
                        data.map(item => {
                            return (
                                <tr onClick={() => openModal(item)}>
                                    <td align='center'><p>{item.titre}</p></td>
                                    <td align='center'><p>{item.artiste}</p></td>
                                    <td align='center'><p>{item.place}</p></td>
                                </tr>
                                
                            )
                        })
                    }
                    
                </tbody>
                {
                    isOpenModal &&

                    <div style={{position: 'absolute', width: '100vw', height: '100vh', top: 0, left: 0}}>
                        <div className='modalBackground' onClick={() => setIsOpenModal(false)}></div>
                        <div className='modal'>
                            <div className='modalSub'>
                                <div>
                                    <p>Titre : </p>
                                    <input type='text' value={current.titre} onChange={(e) => setCurrent({...current, titre: e.target.value})} />
                                </div>
                                <div>
                                    <p>Url : </p>
                                    <input type='text' value={current.url} onChange={(e) => setCurrent({...current, url: e.target.value})} />
                                </div>
                            </div>
                            <div className='modalSub'>
                                <div>
                                    <p>Artiste : </p>
                                    <input type='text' value={current.artiste} onChange={(e) => setCurrent({...current, artiste: e.target.value})} />
                                </div>
                                <div>
                                    <p>Place : </p>
                                    <input type='number' min={0} value={current.place} onChange={(e) => setCurrent({...current, place: e.target.value})} />
                                </div>
                            </div>
                            <div className='modalSub'>
                                <div>
                                    <p>Image : </p>
                                    <UploadWidget setCurrent={setImage} current={image} />
                                </div>
                            </div>
                            <div>
                                {
                                    current.image &&
                                    <img src={current.image} style={{maxHeight: '30vh'}} />
                                }
                            </div>
                            <div className='subActions'>
                                <p className='enregistrer' onClick={() => {!current.id ? addClip() : modify()}}>Enregistrer</p>
                                <p className='annuler' onClick={() => closeReset()}>Annuler</p>
                                <p className='supprimer' onClick={() => deleteClip()}>Supprimer</p>
                            </div>
                        </div>
                    </div>
                }
            </table>
        </div>
    )

}
