import React, { useEffect, useState } from 'react'
import axios from 'axios';

import './admin.css'
import { useNavigate } from 'react-router-dom';
import { PHP_API_SERVER } from '../../API/Routes/config';

export default function Admin() {

    const navigation = useNavigate()

    const [test, setTest] = useState([])

    const [email, setEmail] = useState('')

    const [password, setPassword] = useState('')


    const refetch = () => {
        axios.get(PHP_API_SERVER)
            .then(
                function(response){
                    console.log(response.data)
                }
            )
    }

    const sendData = () => {
        axios.post(PHP_API_SERVER, {
            email: email,
            password: password
          })
          .then(function (response) {
            if (response.data == 1){
                sessionStorage.setItem("user", email);
                navigation('/Dashboard')
            } 
            else {
                return alert('Email ou mot de passe incorrect');
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }

    function ValidateEmail(mail) 
    {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
        {
            return (true)
        }
            alert("L'adresse email n'a pas un format valide!")
            return (false)
    }

    const sendForm = () => {
        if (!email || !password){
            return alert('Veuillez renseigner les champs requis')
        } else {
            let testemail = ValidateEmail(email)

            if (testemail){
                sendData()
            } else {
                return
            }
        }
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <div style={{display: 'flex', flexDirection: "column", gap: 12}}>
                <input onChange={(e) => setEmail(e.target.value)} type='text' placeholder='email' className='inputAdmin'/>
                <input onChange={(e) => setPassword(e.target.value)} type='password' placeholder='password' className='inputAdmin' /> 
                <p onClick={sendForm} className='buttonConnect'>Se connecter</p>
            </div>
           
        </div>

    )
}
