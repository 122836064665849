import React, { useEffect, useState } from 'react'
import './Home.css'

import { useNavigate } from "react-router-dom";


import apple from "../../images/apple.png"
import spotify from "../../images/spotify.png"
import deezer from "../../images/deezer.png"
import youtube from "../../images/youtube.png"
import instagram from "../../images/instagram.png"

import {gsap} from 'gsap'; 


import { useTranslation } from 'react-i18next';

import { PHP_API_BACK, REVEAL_API_BACKGROUND_URL } from '../../API/Routes/config';

import logo from '../../images/pancho ok.png'

import logoRouge from '../../images/ROUGE.png'
import logoViolet from '../../images/FRENCH.png'
import logoBleuClair from '../../images/BLEU CLAIR.png'
import logoRose from '../../images/ROSE SHOCKING.png'
import logoOrange from '../../images/TIGER.png'
import axios from 'axios';

function Home() {

    const navigate = useNavigate();

    const [language, setLanguage] = React.useState(window.localStorage.getItem('language') !== null ? window.localStorage.getItem('language') : 'es')

    const [t, i18n] = useTranslation('global');

    const [background, setBackground] = React.useState(null)

    const ref1 = React.useRef(null)
    const ref2 = React.useRef(null)
    const ref3 = React.useRef(null)
    const ref4 = React.useRef(null)
    const ref5 = React.useRef(null)

    const photo1 = React.useRef(null)
    const photo2 = React.useRef(null)
    const photo3 = React.useRef(null)
    const photo4 = React.useRef(null)
    const photo5 = React.useRef(null)

    const refMainHeight = React.useRef(null) 

    const [ext, setExt] = useState('')

    const [videoOnSafariPlay, setVideoOnSafariPlay] = useState(false);
    const [videoHeight, setVideoHeight] = useState(0)

    const refreshData = async () => {

        try {
            await axios.get(PHP_API_BACK)
            .then(data => {
                let splits = data.data.url.split('.')
                setExt(splits.slice(-1))
                if (data.data === null){
                    setBackground(null)
                } else {
                    setBackground(data.data)
                }
                
            })
            
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        refreshData()
    }, [])

    useEffect(() => {
        window.localStorage.setItem('language', language);
        i18n.changeLanguage(language);
    }, [language, i18n]);


    const Reveal = () => {
        const tl = gsap.timeline({
            onComplete: () => {
            }
        })

        tl.to(".head__main__container__left", {
            delay: 0,
            duration: 0.6,
            opacity: 1
        })
    }

    useEffect(() => {
        Reveal()   

        if (navigator.userAgent.match(/AppleWebKit/) && ! navigator.userAgent.match(/Chrome/)) {
            setVideoOnSafariPlay(true)
        } else {
            setVideoOnSafariPlay(false)
        }

        setVideoHeight(refMainHeight.current.clientHeight)
    }, [])

    const transition_content = () => {
        const tl = gsap.timeline({
            onComplete: () => {
            }
        })

        tl.to(".transition__content", {
            delay: 0,
            duration: 0.6,
            fontSize: "70px"
        })
    }

    const pageTransition = (color, navigationEndpoint) => {


        const tl = gsap.timeline({
            onComplete: () => {

            }
        })

        setTimeout(() => {
            transition_content()
        }, 200)

        tl.to("body", { duration: 0, delay: 0, overflow: "hidden" })
        .to("main", { duration: 0, delay: 0, overflow: "hidden" })
        .to(".transition__container", {
            delay: 0,
            height: "2px",
            width: "2px",
            zIndex: 100,
            backgroundColor: color,
            duration: 0,
            overflow: "hidden"
        }).to(".transition__container", {
            delay: 0,
            duration: 1,
            width: "2500px",
            height: "2500px"
        }).to(".transition__container", {
            duration: 0,
            delay: 1,
            display: "none",
        }).to(".transition__content", {
            duration: 0,
            delay: 0,
            display: "none",
        }).to('.OverflowMenu_wrapper__pSRdd', {
            display: "none"
        })
        .to("body", { duration: 0.4, delay: 0, overflow: "" }).play()
        
        setTimeout(() => {
            navigate(navigationEndpoint)
        }, 700)
        
    }

    const changeStyle = (e, background, color) => {
        e.target.style.background = background
        e.target.style.color = color
    }

    const mobileChangeStyle = () => {
    
    }

    return (
        
        <main className='main__home' style={{
            backgroundImage: `url(${background !== null ? `${background.url}` : "#171614"})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover", 
            overflow: "hidden",
            backgroundPosition: "center",
            objectFit: "cover",
            }}
            ref={refMainHeight}
        >

            {
                background !== null ?
                ext == 'mp4' &&
                    videoOnSafariPlay ? 
                        <video className='video__background' autoplay preload="auto" playsinline loop muted style={{zIndex: -10, position: "absolute", width: "100%", height: "100vh", objectFit: "cover"}}>
                            <source src={background !== null ? `${background.url}` : "#171614"} type="video/mp4"/>
                        </video>
                        :
                        <video className='video__background' autoPlay loop muted style={{ objectFit: "cover", position: "absolute", width: "100%", height: videoHeight, zIndex: -10}}>
                            <source src={background !== null ? `${background.url}` : "#171614"} type="video/mp4"/>
                        </video>    
                :
                null
            }
    
    
            <header className='head__container'> 
                <div></div>
                <div></div>
                <div className='head__main__container__left'>
                    <p onClick={() => setLanguage("es")} style={{color: language === "es" ? "#ffffff" : "#ACACAC"}}>es</p>
                    <p>-</p>
                    <p onClick={() => setLanguage("en")} style={{color: language === "en" ? "#ffffff" : "#ACACAC"}}>en</p>
                    <p>-</p>
                    <p onClick={() => setLanguage("fr")} style={{color: language === "fr" ? "#ffffff" : "#ACACAC"}}>fr</p>
                </div>
            </header>

            <div className='title__container'>
                <h1 className='title'>
                    PanchoGyps
                </h1>
            </div>

            <div className='content__container' style={{zIndex: 1}}>
                
                <div ref={ref1} onTouchStart={() => mobileChangeStyle()}  className='content__container__card card1' onClick={() => pageTransition("#8806CE" ,"/Productions")}>
                    <p className="marquee marquee1" datacustomattributes={t("home.card1")}>
                        {t("home.card1")}
                    </p>
                    <p className="marquee marquee1 inversed" datacustomattributes={t("home.card1")}>
                        {t("home.card1")} 
                    </p>
                    {/* <p>PA</p> */}
                    <img ref={photo1} src={logoViolet} alt="Pancho miniature"></img>
                </div>
                <div ref={ref2} className='content__container__card card2' onClick={() => pageTransition("#68B8E6" , "/Clips")}>
                    <p className="marquee marquee2" datacustomattributes={t("home.card2")}>
                        {t("home.card2")} 
                    </p>
                    <p className="marquee marquee2 inversed" datacustomattributes={t("home.card2")}>
                        {t("home.card2")}
                    </p>
                    <img src={logoBleuClair} alt="Pancho miniature" style={{background: "transparent"}}></img>
                </div>
                <div ref={ref3} className='content__container__card card5'>
                    <p className="marquee marquee5" datacustomattributes={t("home.card5")}>
                        {t("home.card5")} 
                    </p>
                    <p className="marquee marquee5 inversed" datacustomattributes={t("home.card5")}>
                        {t("home.card5")} 
                    </p>
                    <img src={logoRouge} alt="Pancho miniature" style={{background: "transparent"}}></img>
                </div>
                <div ref={ref4} className='content__container__card card3' onClick={() => pageTransition("#FD6A02" , "/Discographie")}>
                    <p className="marquee marquee3" datacustomattributes={t("home.card3")}>
                        {t("home.card3")} 
                    </p>
                    <p className="marquee marquee3 inversed" datacustomattributes={t("home.card3")}>
                        {t("home.card3")} 
                    </p>
                    <img src={logoOrange} alt="Pancho miniature" style={{background: "transparent"}}></img>
                </div>
                <div ref={ref5} className='content__container__card card4' onClick={() => pageTransition("#FC0FC0" , "/Apropos")}>
                    <p className="marquee marquee4" datacustomattributes={t("home.card4")}>
                        {t("home.card4")} 
                    </p>
                    <p className="marquee marquee4 inversed" datacustomattributes={t("home.card4")}>
                        {t("home.card4")} 
                    </p>
                    <img src={logoRose} alt="Pancho miniature" style={{background: "transparent"}}></img>
                </div>
            </div>

            <div>
                <div 
                    className='transition__container'
                    style={{
                        position: "absolute",
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 0,
                        borderRadius: "50%",
                        height: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                    }}
                />
                <p 
                    className='transition__content' 
                    style={{
                        color: "black", 
                        zIndex: 100, 
                        position: "absolute", 
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)', 
                        fontFamily: "deathrattle",
                        fontSize: 0
                    }}
                >
                    Panchogyps
                </p>
            </div>

            <footer className='footer__container'>
                <a href='https://www.instagram.com/panchogyps/' target='_blank'><img src={instagram} alt="instagram" /></a>
                <a href='https://open.spotify.com/intl-fr/artist/40bSN1L4G2gvHInRDkBvON?si=oj6rj-XURAKFGNeUd6GvDQ' target='_blank'><img src={spotify} alt="spotify" /></a>
                <a href='https://www.youtube.com/@panchogyps' target='_blank'><img src={youtube} alt="youtube" /></a>
                <a href='https://music.apple.com/ci/artist/panchogyps/1560235969' target='_blank'><img src={apple} alt="apple" /></a>
                <a href='https://www.deezer.com/fr/artist/88468702' target='_blank'><img src={deezer} alt="deezer" /></a>
                
                
            </footer>

            <iframe className='iframe__spotify' src="https://open.spotify.com/embed/playlist/0uxwk6Z5in935RgDoAQwIU?utm_source=generator&theme=0" width="90%" height="100" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" title='PanchoGyps playlist spotify'></iframe>

        </main>
    )
    
}

// onTouchEnd={(e) => e.target.style.background = background !== null ? 'rgb(42, 41, 41, 0.9)' : 'rgb(40, 40, 40)'}
export default Home